@import '../../styles/colors';
@import '../../styles/reusables';
@import '../../styles/sizes';

#e_invoices {
  display: flex;
  flex-direction: column;
  position: relative;

  .header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    color: $dark_gray;
    font-size: 15px;
    font-weight: bold;
    height: 48px;
    padding: 5px 16px;

    .right_side {
      display: flex;
      gap: 10px;

      i {
        font-size: 16px;
        margin-right: 0;
        color: $main;
        cursor: pointer;
      }
    }
  }

  .search_block {
    position: absolute;
    top: -48px;
    left: 0;
    width: 100%;
    transition: top 0.2s linear;

    & > div {
      padding: 12px 16px 4px;
      display: flex;
      align-items: center;
      background-color: $white;

      .search_body {
        position: relative;
        width: 100%;

        i:global(.x.icon) {
          position: absolute;
          right: 4px;
          top: 8px;
          color: $dark_gray;
          font-weight: 200;
          cursor: pointer;
        }
      }

      :global(.ui.search),
      :global(.input) {
        width: 100%;
        height: 32px;
        input {
          border-radius: 4px;
          border: 1px solid #e0e8fe;
          padding-right: 25px !important;
        }
      }
      i {
        color: #e0e8fe;
      }
      .cancel {
        margin-left: 10px;
        padding: 6px 0;
        color: $main;
        cursor: pointer;
      }
    }
    &.openSearch {
      top: 0;
    }
  }

  .title {
    display: flex;
    justify-content: space-between;
    font-size: 12px;
    padding: 8px 16px;
    box-shadow: 0 4px 5px 0 #ccc;

    p {
      margin: 0;
    }
    .vendor_info {
      display: flex;
      align-items: center;
      text-transform: uppercase;
    }
    .vendor_logo {
      width: 50px;
      min-width: 50px;
      height: 50px;
      margin-right: 10px;
    }
    .vendor_name {
      font-weight: bold;
      color: $main;
      font-size: 12px;
    }
    .vendor_number {
      display: flex;
      flex-direction: column;
      justify-content: center;
      text-transform: uppercase;

      & > p:last-child {
        color: $main;
      }
    }
  }

  :global(.ui.list) {
    overflow-y: auto;
    padding: 0 16px;
    margin: 0;
    height: calc(100vh - 114px);

    &::-webkit-scrollbar {
      width: 0;
    }
  }

  :global(.ui.list .item) {
    display: flex;
    box-shadow: $box-shadow;
    padding: 16px;
    margin: 16px 0px;

    .item_description {
      flex-grow: 1;
      font-size: 10px;

      .row {
        display: flex;

        .info {
          line-height: 12px;
          font-size: 10px;

          .label {
            margin-bottom: 3px;
            color: $dark_gray;
            text-transform: uppercase;
            white-space: nowrap;
          }

          .value {
            color: $main;
            font-size: 12px;
            font-weight: bold;

            &.amount {
              font-weight: bold;
            }
          }

          .date {
            color: $black;
          }

          &:first-child {
            width: 200px;
          }
        }

        &:first-child {
          margin-bottom: 10px;
        }
      }

      @media (max-width: $mobile-width) {
        .row {
          .info:first-child {
            width: 130px;
          }
        }
      }
    }
    .wallet_icon {
      padding-left: 16px;
      margin-left: 6px;
      border-left: 1px solid #e0e8fe;
      display: flex;
      align-items: center;
      min-width: 40px;
    }
  }
}
