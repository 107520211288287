@import '../../styles/colors';

.currency {
  position: absolute;
  right: 15px;
  font-size: 11px;
  color: $dark_gray;
  line-height: 46px;
}
.warning_icon_block {
  height: 70%;
  position: absolute;
  top: 15%;
  right: 0;
  display: flex;
  align-items: center;
  font-size: 18px;
  color: $gray2;
  padding: 0 14px;

  .warning_icon {
    position: relative;

    i {
      margin: 0 !important;
      width: 20px;
      cursor: pointer;
    }
    :global(.sign) {
      color: $yellow2;
    }
    .popover {
      position: absolute;
      bottom: 30px;
      right: -14px;
      background-color: $white;
      font-size: 10px;
      box-shadow: 0 2px 4px 0 rgba(34, 36, 38, 0.1), 0 2px 10px 0 rgba(34, 36, 38, 0.15);
      padding: 6px;
      width: 250px;
      border: 1px solid #d4d4d5;
      line-height: 1.4285em;
      max-width: 250px;
      font-weight: 400;
      font-style: normal;
      color: rgba(0, 0, 0, 0.87);
      border-radius: 0.28571429rem;

      &::after {
        content: '';
        width: 10px;
        height: 10px;
        background-color: $white;
        position: absolute;
        bottom: -5px;
        right: 18px;
        transform: rotateZ(45deg);
        box-shadow: 1px 1px 0 0 #cbcbcb;
      }
    }
  }
}
