.toast {
  padding-left: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 15px;
  font-weight: bold;

  .success {
    color: #3e982f;
  }

  .error {
    color: #a40000;
  }

  img {
    margin-right: 10px;
    height: 20px;
  }
}
