@import '../../../../styles/colors';
@import '../../../../styles/sizes';

.invoice_list_modal {
  padding: 20px;
  position: relative;
  height: calc(100% - 40px);
  &:global(.visible.transition) {
    display: flex !important;
    flex-direction: column;
  }

  p {
    margin: 0;
  }
  .header {
    display: flex;
    align-items: center;
    justify-content: center;
    text-transform: uppercase;

    i {
      color: $main;
      position: absolute;
      top: 10px;
      right: 10px;
      margin-right: 0;
      font-size: 18px;
      cursor: pointer;
    }
  }
  .filters {
    margin-top: 24px;
    display: flex;
    flex-direction: column;
    align-items: center;
    .input_block {
      width: 100%;
      position: relative;

      .icon_block {
        position: absolute;
        top: 6px;
        right: 8px;
        width: 18px;
        cursor: pointer;
      }
    }
    .field {
      width: 100%;
      border: 1px solid #e0e8fe;
      border-radius: 6px;
      outline: none;
      padding: 8px;
    }
    .date_picker_block {
      width: 100%;
      display: flex;
      justify-content: center;
      position: relative;

      .date_picker {
        width: 100%;
        padding: 10px;
        position: absolute;
        z-index: 1111;
        display: flex;
        flex-direction: column;
        align-items: flex-end;
        background-color: $white;
        box-shadow: 0px 3px 6px #00000029;
        border-radius: 4px;

        & > div {
          width: 100%;
          display: flex;
          justify-content: center;

          :global {
            .react-datepicker {
              width: 100%;
              display: flex;
              justify-content: center;
            }
            .react-datepicker__month-container {
              width: 100%;
            }
            .react-datepicker__day-names {
              display: flex;
            }
            .react-datepicker__day-name {
              flex: 1;
            }
            .react-datepicker__week {
              display: flex;
              align-items: center;
            }
            .react-datepicker__day {
              flex: 1;
            }
          }
        }
        :global(.ui.basic.button) {
          color: $main !important;
          border: 1px solid $main;
          margin-right: 0;
          align-content: center;
          margin-top: 10px;
        }
        :global {
          .react-datepicker__day--in-selecting-range,
          .react-datepicker__day--keyboard-selected {
            background-color: $red3;
            color: $black;
          }
          .react-datepicker__day--in-range,
          .react-datepicker__day--selected {
            background-color: $main !important;
            color: $white;
          }
          .react-datepicker__header {
            background-color: $white !important;
          }
        }
      }
      :global(.ui.basic.button) {
        background-color: $white !important;
      }
    }
  }
  .content {
    margin-top: 11px;
    flex: 1;
    overflow-y: auto;
    position: relative;

    &::-webkit-scrollbar {
      width: 4px;
    }
    .content_item {
      border: 1px solid $gray2;
      border-radius: 6px;
      padding: 10px 14px;
      margin-bottom: 16px;
      text-transform: uppercase;
      box-shadow: 0px 0px 6px #00000029;
      cursor: pointer;
      display: flex;
      font-size: 10px;
    }

    .item_column {
      &:not(:first-child) {
        margin-left: 100px;

        @media (max-width: $mobile-width) {
          margin-left: 40px;
        }
      }
    }
    .content_item_line {
      white-space: nowrap;

      &:first-child {
        color: $dark_gray;
      }
      &:not(:first-child) {
        margin-top: 10px;
      }
      .important_line {
        color: $main;
        font-weight: bold;
        font-size: 12px;
      }
    }
    .empty_block {
      height: 100%;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      text-align: center;
      color: $gray4;

      :global(.search.icon) {
        font-size: 29px;
        margin: 0;
      }
      p {
        width: 172px;
        margin-top: 23px;
      }
    }
  }
}
