@import '../../../styles/colors';
@import '../../../styles/reusables';

#sub_menu {
  .title {
    color: #ffffff;
    text-transform: uppercase;
    font-size: 10px;
    position: relative;
    padding: 16px 20px 16px 33px;

    span {
      margin-right: 10px;
    }

    .img {
      width: 16px;
      margin-right: 25px;
      display: inline;
    }

    .arrow_icon {
      position: absolute;
      right: 16px;
    }
  }

  .content {
    padding: 0 20px 0 33px;
  }

  .menu_item {
    padding: 16px 0 16px 24px !important;
    margin-bottom: 0 !important;

    .item_block {
      color: $white;
      text-transform: uppercase;
      font-size: 10px;
      display: flex;
      align-items: center;

      & > div {
        display: inline-flex;
        justify-content: center;
        align-items: center;
        width: 45px;
      }
      .menu_item_icon {
        float: left;
        height: 14px;
      }
    }
  }
}
