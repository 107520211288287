@import '../../styles/colors';
@import '../../styles/sizes';

#login_screen {
  display: flex;
  flex-direction: column;
  height: 100vh;

  .login_container {
    width: 80%;
    margin: 0 auto;
    padding-top: 60px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    max-width: 600px;

    img {
      display: block;
      margin: 0 auto 30px;
      width: 150px; // set width and height here not in the svg, so that it works on Edge
      height: 50px;
    }

    .login_input {
      input,
      input:focus {
        background-color: $light_gray;
        border-color: #d0cfcf;
        height: 40px;
        color: #2e385d;
        line-height: 18px;
        filter: none; // removes autofill color on firefox
      }

      // remove autofill color on other browsers
      input:-webkit-autofill,
      input:-webkit-autofill:hover,
      input:-webkit-autofill:focus,
      input:-webkit-autofill:active {
        box-shadow: 0 0 0 30px $light_gray inset !important;
        -webkit-box-shadow: 0 0 0 30px $light_gray inset !important;
        border-color: #d0cfcf !important;
      }
    }

    .label {
      color: $dark_gray;
      line-height: 14px;
      margin-bottom: 10px;
      display: flex;
      justify-content: space-between;
      font-weight: unset;
      text-transform: uppercase;
      text-align: end;

      a {
        color: $mojo_red;
      }
    }

    .manual {
      display: flex;
      align-self: center;
      font-size: 14px;
      text-decoration: underline;
      margin-bottom: 40px;
    }

    .linkIcon {
      width: 12px;
      height: 19px;
      margin: 0px 0px 0px 4px;
    }

    .forgot_password {
      font-size: 10px;
    }
    .sign_up {
      text-align: center;
      margin-top: 24px;
      color: $main;
    }
  }

  @media (max-width: $mobile-width) {
    & > form {
      flex-grow: 1;
    }
  }
}
