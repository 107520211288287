@import '../../styles/colors';
@import '../../styles/sizes';
@import '../../styles/reusables';

#verification_screen {
  display: flex;
  flex-direction: column;
  height: 100vh;

  .header {
    height: $hamburger-menu-height;
    padding: 15px;
    display: flex;
    justify-content: flex-end;
    grid-template-columns: 1fr 1fr 1fr;
    gap: 10px;
    color: $main;

    div {
      cursor: pointer;
      text-transform: uppercase;
    }
  }
  .verification_container {
    width: 80%;
    margin: 0 auto;
    padding-top: 60px;
    display: flex;
    flex-direction: column;
    max-width: 600px;
    text-align: center;

    img {
      width: 150px;
      margin: 0 auto;
    }
    .title {
      margin-top: 40px;
      color: $dark_gray;
      font-size: 18px;
    }
    .text {
      margin-top: 24px;
    }
    :global(.divider) {
      margin-top: 110px;
    }
    .resend {
      background-color: white;
      color: $main;
      font-weight: normal;
      padding: 0;
      margin-top: 5px;
      text-transform: uppercase;
    }
  }

  @media (max-width: $mobile-width) {
    & > form {
      flex-grow: 1;
    }
  }
}
