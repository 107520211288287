@import '../../styles/colors';
@import '../../styles/sizes';

#new_transfer {
  .new_transfer_header {
    background-color: $main;
    height: 38px;
    display: table;
    color: $white;
    width: 100%;

    .header_column {
      width: 50%;
      padding: 0 10px;
      vertical-align: middle;
      display: table-cell;
      text-align: center;
    }
  }

  .arrow_icon {
    bottom: 14px;
  }

  .transfer_info_container {
    border: 0;
    border-radius: 0;
    margin: 0;
    box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16);
    padding: 15px 0;

    .row {
      display: flex;

      .container {
        display: flex;
        padding-left: 15%;
        padding-right: 30px;
        word-break: break-word;

        .business_info {
          color: $main;
          font-size: 13px;
          line-height: 18px;
          font-weight: bold;
          margin-left: 12px;
        }

        .avatar_image {
          border: 1px solid #e0e0e0;
          width: 35px;
          height: 35px;
        }

        .info_block {
          .label_info {
            color: $dark_gray;
            font-size: 14px;
            line-height: 16px;
            margin-bottom: 3px;
          }

          .info {
            color: $main;
            font-size: 12px;
            font-weight: bold;
          }
        }
      }

      & > div {
        width: 50%;
      }

      &:first-child {
        margin-bottom: 15px;

        .container {
          align-items: center;
        }
      }
    }
  }

  @media (max-width: $mobile-width) {
    .transfer_info_container {
      .row {
        .container {
          padding-left: 18px;
          padding-right: 3px;

          .business_info {
            margin-left: 6px;
          }

          .avatar_image {
            width: 33px;
            height: 33px;
          }
        }

        &:first-child {
          .container {
            padding-left: 10px;

            &:first-child {
              padding-right: 5px;
            }
          }
        }

        .container:first-child {
          padding-right: 15px;
        }
      }
    }
  }

  .new_transfer_form_container {
    padding: 30px;

    .field {
      position: relative;

      .icon_block {
        height: 70%;
        position: absolute;
        top: 15%;
        right: 0;
        border-left: 1px solid rgba(34, 36, 38, 0.15);
        padding: 0 16px;
        display: flex;
        align-items: center;
        cursor: pointer;

        .list_icon {
          width: 22px;
        }
        .date_icon {
          width: 16px;
        }
      }
    }

    .transfer_info {
      border-bottom: 1px solid rgba(34, 36, 38, 0.15);
      margin-bottom: 20px;
      padding-bottom: 13px;
      font-size: 12px;
      text-transform: uppercase;

      & > div {
        display: flex;
        justify-content: space-between;

        p:first-child {
          color: $dark_gray;
          margin: 0;
        }
      }
    }

    .label_info {
      margin-bottom: 5px;
      display: flex;
      justify-content: space-between;

      span {
        color: $dark_gray;
        font-size: 12px;
        line-height: 11px;
        margin-bottom: 3px;
      }

      .fee_amount {
        color: $pink;
      }
    }

    .number_input {
      width: 100%;
    }

    .notes_input {
      resize: none;
      min-height: 100px;
      width: 100%;
    }

    input,
    textarea {
      color: $dark_red;
    }

    input:focus,
    textarea:focus {
      border-color: $dark_gray;
    }

    .back_button {
      width: 100%;
      margin-top: 10px;
    }
  }
}

.invoice_list_modal {
  padding: 20px;

  p {
    margin: 0;
  }
  .header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    text-transform: uppercase;

    i {
      color: $main;
    }
  }
  .content {
    margin-top: 30px;
    max-height: 60vh;
    overflow-y: auto;

    &::-webkit-scrollbar {
      width: 0;
    }
    .content_item {
      border: 1px solid $gray2;
      border-radius: 6px;
      padding: 10px 14px;
      margin-bottom: 8px;
      text-transform: uppercase;
      cursor: pointer;
    }

    .content_item_line {
      display: flex;
      justify-content: space-between;
      white-space: nowrap;

      &:not(:first-child) {
        margin-top: 6px;
      }
      .invoice_number {
        color: $main;
        font-weight: bold;
      }
      .amount {
        font-weight: bold;
      }
    }
  }
}
