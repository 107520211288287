@import '../../../../styles/colors';
@import '../../../../styles/sizes';
@import '../../../../styles/reusables';

#loan_details {
  $border: #d4d4d5;

  padding: 0 10px;

  .container {
    width: 100%;

    .header {
      font-size: 12px;
      font-weight: bold;
    }

    .footer {
      border-top: 1px solid $border;
      padding: 8px 5px;
    }

    .card {
      width: 100%;
      box-shadow: $box-shadow;

      .content {
        padding: 8px 5px;
      }
    }

    .details_row {
      display: flex;
      flex-direction: row;
      margin: 0 15px;

      &.first {
        margin-top: 0;
        border-bottom: 1px solid $border;

        .details_col {
          font-weight: bold;
        }
      }

      .details_col {
        font-size: 14px;
        text-align: left;
        padding: 5px 0;
        word-break: break-word;
        width: 50%;
        font-weight: normal;
        color: $dark_gray;

        &.colored {
          color: $main;
        }

        &:first-child {
          min-width: 185px;

          @media (max-width: $mobile-width) {
            min-width: 155px;
          }
        }
      }

      .details_col_label {
        padding: 5px 14px 5px 0;
        background-color: #ffffff;
        line-height: 20px;
        color: $dark_gray;

        .date {
          color: #80808091;
        }
      }
    }
  }
}
