@import '../../styles/colors';

#fullscreen_inline_modal {
  padding: 14px;

  .modal_header {
    font-size: 14px;
    text-align: center;
    font-family: inherit;
    text-transform: uppercase;
    border-bottom: none;
    color: $dark_gray;

    .arrow_icon {
      float: left;
      color: $dark_gray;
    }
  }
}
