@font-face {
  font-family: 'DejaVu Sans Book';
  src: url('./styles/fonts/DejaVuSans/DejaVuSans.ttf');
}

@font-face {
  font-family: 'DejaVu Sans Bold';
  src: url('./styles/fonts/DejaVuSans/DejaVuSans-Bold.ttf');
}

@font-face {
  font-family: 'DejaVu Sans Bold Oblique';
  src: url('./styles/fonts/DejaVuSans/DejaVuSans-BoldOblique.ttf');
}

@font-face {
  font-family: 'DejaVu Sans Extra Light';
  src: url('./styles/fonts/DejaVuSans/DejaVuSans-ExtraLight.ttf');
}

@font-face {
  font-family: 'DejaVu Sans Oblique';
  src: url('./styles/fonts/DejaVuSans/DejaVuSans-Oblique.ttf');
}

@font-face {
  font-family: 'DejaVu Sans Condensed';
  src: url('./styles/fonts/DejaVuSans/DejaVuSansCondensed.ttf');
}

@font-face {
  font-family: 'DejaVu Sans Condensed Bold';
  src: url('./styles/fonts/DejaVuSans/DejaVuSansCondensed-Bold.ttf');
}
@font-face {
  font-family: 'DejaVu Sans Condensed Bold Oblique';
  src: url('./styles/fonts/DejaVuSans/DejaVuSansCondensed-BoldOblique.ttf');
}
@font-face {
  font-family: 'DejaVu Sans Condensed Oblique';
  src: url('./styles/fonts/DejaVuSans/DejaVuSansCondensed-Oblique.ttf');
  src: url('./styles/fonts/DejaVuSans/DejaVuSans.ttf');
}

@font-face {
  font-family: 'icomoon';
  src: url('./styles/icons/iconmoon/fonts/icomoon.eot?1gzxx0');
  src: url('./styles/icons/iconmoon/fonts/icomoon.eot?1gzxx0#iefix')
      format('embedded-opentype'),
    url('./styles/icons/iconmoon/fonts/icomoon.ttf?1gzxx0') format('truetype'),
    url('./styles/icons/iconmoon/fonts/icomoon.woff?1gzxx0') format('woff'),
    url('./styles/icons/iconmoon/fonts/icomoon.svg?1gzxx0#icomoon')
      format('svg');
  font-weight: normal;
  font-style: normal;
}
