@import '../../../styles/colors';
@import '../../../styles/sizes';

#details_container {
  max-width: 35%;
  text-align: center;
  margin: 15px auto;

  .image_wrapper > div {
    height: 75px;
  }

  img {
    margin-top: 15px;
  }

  .avatar_image {
    width: auto;
    height: 100%;
  }

  .long_label_container .details_col_label {
    min-width: 170px;
  }

  .medium_label_container .details_col_label {
    min-width: 150px;
  }

  .short_label_container .details_col_label {
    min-width: 130px;
  }

  .container {
    display: inline-block;

    .details_row {
      display: flex;
      flex-direction: row;
      margin: 5px 15px;

      .details_col {
        font-size: 12px;
        text-align: left;
        padding: 5px 0;
        word-break: break-word;
        width: 50%;
        color: $main;
        display: flex;
        align-items: center;
      }

      .details_col_label {
        padding: 5px 7px 10px 0;
        text-transform: uppercase;
        color: $dark_gray;
        font-weight: inherit;
        background-color: #ffffff;
        line-height: 20px;
      }
    }
  }

  .row {
    display: flex;
    flex-direction: row-reverse;
    justify-content: center;

    .centered_button {
      margin: 10px;
    }
  }
}

@media (max-width: $large-tablet-width) {
  #details_container {
    max-width: 75%;
  }
}

@media (max-width: $mobile-width) {
  #details_container {
    max-width: 100%;
    .row {
      display: block;

      .centered_button {
        display: block;
        margin: 10px auto;
      }
    }
  }
}
