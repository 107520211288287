@import '../../../styles/colors';

.vendor_item_container {
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  .vendor_logo {
    margin-bottom: 10px;
  }

  .vendor_name {
    font-size: 12px;
    text-align: center;
    color: $dark_red;
    text-overflow: ellipsis;
    overflow: hidden;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
  }

  .vendor_suppliers {
    color: $dark_gray;
  }
}
