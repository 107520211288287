@import '../../styles/colors';
@import '../../styles/sizes';
@import '../../styles/reusables';

#cards_container {
  justify-content: center;
  margin-top: 9px;
  padding: 0 20px;
  white-space: nowrap;

  .single_card {
    border-radius: 4px;
    padding: 10px 16px;
    box-shadow: $box-shadow;
    flex: 1 0;
    margin: 10px;

    .link {
      display: flex;
      flex: 1;

      .link_content {
        flex-grow: 1;
      }
    }

    .first_card {
      padding: 0;

      .first_card_content {
        display: flex;
        flex-direction: column;

        img {
          margin-bottom: 5px;
        }
        .card_row {
          display: flex;
          justify-content: space-between;

          .card_header {
            margin-top: 0;
          }
        }
      }
    }

    .card_icons {
      width: 26px;
      height: 21px;
      object-fit: contain;
      background-color: transparent;
      margin-bottom: 5px;
    }

    .card_header {
      text-transform: uppercase;
      color: $dark_gray;
      font-size: 10px;
    }

    .loan_limit,
    .payment_date {
      color: $main;
      font-weight: bolder;
      line-height: 19px;
    }

    .payment_date {
      line-height: 16px;
      text-transform: uppercase;
      font-size: 14px;
    }
  }

  .img {
    margin-left: 5px;
    height: 18px;
    align-self: center;
  }

  @media (min-width: $large-tablet-width) {
    .single_card {
      text-align: center;
    }

    .card_icons {
      margin: auto;
    }
  }

  @media (max-width: $mobile-width) {
    .single_card {
      padding-left: 9px;
      padding-right: 9px;

      .first_card_content {
        width: 100%;
      }

      .card_header {
        font-size: 9px;
        line-height: 10px;
        margin-bottom: 7px;
      }

      .payment_date {
        font-size: 11px;
      }
    }
  }
}
