@import '../../styles/colors';
@import '../../styles/reusables';
@import '../../styles/sizes';

.vendor_container {
  padding: 10px;
  color: $dark_gray;
  position: relative;
  margin-top: 5px;

  .vendor_items_container {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
  }

  .vendor_header {
    font-size: 10px;
    margin: 10px;
    text-transform: uppercase;
  }

  .vendor_item {
    box-shadow: $box-shadow;
    border-radius: 4px;
    padding: 11px 10px 10px;
    margin: 10px;
    font-size: 10px;
    width: 150px;
  }

  @media (max-width: $tablet-width) {
    .vendor_item {
      max-width: 155px;
      height: auto;
    }
  }

  @media (max-width: $mobile-width) {
    .vendor_item {
      max-width: 130px;
    }
  }
}
