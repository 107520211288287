@import '../../styles/colors';
@import '../../styles/reusables';
@import '../../styles/sizes';

#transfer_types {
  padding: 20px 16px;
  margin: 0;

  .transfer_type {
    height: 76px;
    box-shadow: 0px 0px 6px #00000029;
    border-radius: 6px;
    padding: 0 16px;
    margin-bottom: 16px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    font-size: 16px;
    cursor: pointer;

    .content {
      display: flex;
      align-items: center;

      img {
        width: 30px;
        margin-right: 20px;
      }
    }
    .arrow_img {
      width: 9px;
    }
  }
}
