@import '../../styles/colors';
@import '../../styles/sizes';

#sign_up_screen {
  display: flex;
  flex-direction: column;
  height: 100vh;

  .sign_up_container {
    width: 80%;
    margin: 0 auto;
    padding-top: 60px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    max-width: 600px;

    img.logo {
      display: block;
      margin: 0 auto 50px;
      width: 150px; // set width and height here not in the svg, so that it works on Edge
      height: 50px;
    }

    .form_title {
      text-align: center;
      font-size: 18px;
      margin-bottom: 35px;
    }

    .input {
      input,
      input:focus {
        background-color: $light_gray;
        border-color: #d0cfcf;
        height: 40px;
        color: #2e385d;
        line-height: 18px;
        filter: none; // removes autofill color on firefox
      }

      // remove autofill color on other browsers
      input:-webkit-autofill,
      input:-webkit-autofill:hover,
      input:-webkit-autofill:focus,
      input:-webkit-autofill:active {
        box-shadow: 0 0 0 30px $light_gray inset !important;
        -webkit-box-shadow: 0 0 0 30px $light_gray inset !important;
        border-color: #d0cfcf !important;
      }
    }

    .phone_input {
      position: relative;

      .input_prefix {
        display: flex;
        position: absolute;
        left: 11px;
        top: 11px;
        width: 18px;
        height: 18px;

        img {
          margin-right: 10px;
        }
      }
      & > input {
        padding-left: 82px;
      }
    }

    .label {
      color: $dark_gray;
      line-height: 14px;
      margin-bottom: 10px;
      display: flex;
      justify-content: space-between;
      font-weight: unset;
      text-transform: uppercase;
      text-align: end;
      font-size: 14px;

      a {
        color: $mojo_red;
      }
    }

    .checkbox label {
      display: inline-flex;
      align-items: center;

      .input {
        width: auto;
        margin-right: 12px;
        cursor: pointer;
        width: 24px;
        height: 24px;

        input {
          height: auto;
          cursor: pointer;
        }
      }
      span {
        white-space: nowrap;
      }
    }

    .cancel {
      width: 100%;
      background-color: white;
      border: 1px solid $dark_gray;
      color: $dark_gray;
      margin-top: 16px;
      height: 50px;
      text-transform: uppercase;
    }
  }

  @media (max-width: $mobile-width) {
    & > form {
      flex-grow: 1;
    }
  }
}
