$white: #ffffff;
$black: #000000;
$pink: #ff9696;
$darker-skin: #ffdede;
$light_gray: #f5f5f5;
$dark_gray: #443c38;
$gray2: #e0e8fe;
$gray3: #8798ad;
$gray4: #828282;
$main: #8f130a;
$mojo_red: #c44141;
$dark_red: #572222;
$red2: #db5454;
$red3: #ffe7e5;
$green: #6ba85d;
$yellow: #ffd700;
$yellow2: #fea521;
