@import '../../../styles/colors';
@import '../../../styles/reusables';
@import '../../../styles/sizes';

.e_invoice_date_modal {
  padding: 16px;
  border-radius: 18px !important;

  .header {
    display: flex;
    justify-content: space-between;
    font-size: 20px;

    :global(.x.icon) {
      margin: 0;
      font-size: 18px;
      color: $main;
      cursor: pointer;
    }
    p {
      margin: 0;
    }
  }

  .content {
    margin-top: 45px;

    .content_item {
      border-bottom: 1px solid #e0e8fe;
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding: 0 8px;
      font-size: 16px;

      p {
        margin: 0;
        white-space: nowrap;
      }

      .date_dicker_wrapper {
        input {
          height: 50px;
          border: none;
          outline: none;
          width: 140px;
          text-align: right;
          color: $main;
        }
      }
    }
  }

  .footer {
    margin-top: 32px;
    display: flex;
    flex-direction: column;

    .save_button {
      background-color: $main;
      color: $white;
      height: 46px;
      border: none;
      border-radius: 6px;
      cursor: pointer;
    }

    .cancel_button {
      height: 46px;
      margin-top: 16px;
      background-color: $white;
      border: 1px solid $main;
      color: $main !important;
      border: 1px solid $main;
      border-radius: 6px;
      cursor: pointer;
    }
  }
}
