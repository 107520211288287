@import '../../styles/colors';

:global .ui.button {
  &.gc_default,
  &.gc_default:focus,
  &.gc_default:hover {
    font-size: 15px;
    color: $white;
    height: 50px;
    text-transform: uppercase;
    font-weight: unset;

    &.gc_primary,
    &.gc_primary:hover,
    &.gc_primary:focus {
      background-color: $dark_gray;
    }

    &.gc_action,
    &.gc_action:hover,
    &.gc_action:focus {
      background-color: $dark_gray;
    }

    &.gc_secondary,
    &.gc_secondary:hover,
    &.gc_secondary:focus {
      background-color: white;
      border: 1px solid $main;
      color: $main;
    }

    &.gc_large {
      width: 100%;
    }
  }
}
