@import '../../styles/reusables';
@import '../../styles/colors';

.header {
  height: $hamburger-menu-height;
  padding: 15px;
  display: grid;
  justify-content: space-between;
  grid-template-columns: 1fr 1fr 1fr;
  gap: 10px;

  .anchor_container {
    color: $main;
    width: max-content;
  }
  .title {
    text-align: center;
    text-transform: uppercase;
  }
}
