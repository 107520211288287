@import '../../styles/colors';
@import '../../styles/reusables';
@import '../../styles/sizes';

#notifications {
  :global(.ui.list) {
    margin-top: 0;
    margin-bottom: 0;

    .item:first-child {
      padding-top: 10px;
    }

    .item:last-child {
      padding-bottom: 10px;
    }
  }

  :global(.ui.list .item) {
    padding: 10px;
    margin: 10px;
    border: 1px solid #cad7e3;
    border-radius: 8px;

    .header {
      color: $main;
      font-size: 11px;
      line-height: 15px;
      font-weight: bold;
      margin-bottom: 10px;

      .icon {
        margin: 0 6px 0 0;
        height: 15px;
      }
    }

    .description {
      color: #707070;
      word-break: break-word;
      font-size: 11px;
      line-height: 14px;
      font-weight: bolder;
    }
  }
}

.empty {
  padding: 15px;
  color: $dark_red;
  text-align: center;
}
