@import '../../styles/colors';
@import '../../styles/sizes';

#reset_password_screen {
  display: flex;
  flex-direction: column;
  height: 100vh;

  .container {
    width: 80%;
    margin: 0 auto;
    padding-top: 7px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    max-width: 600px;

    .title {
      color: $dark_red;
      font-size: 17px;
      text-align: center;
      margin-bottom: 30px;
    }

    img {
      display: block;
      margin: 0 auto 30px;
      width: 150px;
      height: 50px;
    }

    .label {
      color: $dark_gray;
      line-height: 14px;
      margin-bottom: 10px;
      font-weight: unset;
      text-transform: uppercase;
    }

    .login_input {
      input,
      input:focus {
        background-color: #f9faff;
        border-color: #e0e7ff;
        font-family: Helvetica Arial, serif;
        height: 40px;
        color: #2e385d;
        line-height: 18px;
      }
    }
  }

  @media (max-width: $mobile-width) {
    & > form {
      flex-grow: 1;
    }
  }
}
