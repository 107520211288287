@import '../../styles/colors';
@import '../../styles/sizes';

#email_verify_screen {
  display: flex;
  flex-direction: column;
  height: 100vh;

  .email_verify_container {
    width: 80%;
    margin: 0 auto;
    padding-top: 60px;
    display: flex;
    flex-direction: column;
    align-items: center;
    max-width: 600px;
    text-align: center;

    img {
      width: 80px;
    }
    .title {
      margin-top: 40px;
      color: $dark_gray;
      font-size: 18px;
    }
    .text {
      margin-top: 24px;
    }
    .verify_button {
      background-color: $main;
      color: $white;
      width: 100%;
      height: 50px;
      margin-top: 24px;
    }
    .back_to_home_button {
      width: 100%;
      background-color: white;
      border: 1px solid $main;
      color: $main;
      margin-top: 32px;
      height: 50px;
      text-transform: uppercase;
    }
  }

  @media (max-width: $mobile-width) {
    & > form {
      flex-grow: 1;
    }
  }
}
