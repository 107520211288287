@import '../../../styles/colors';
@import '../../../styles/reusables';
@import '../../../styles/sizes';

#history_list {
  .item {
    margin: 10px;

    .list_header {
      color: $main;
      font-size: 12px;
      line-height: 16px;
      margin-bottom: 8px;
    }

    .content {
      display: flex;
      align-items: center;
      box-shadow: $box-shadow;
      padding: 10px;

      .image {
        width: 55px;
        height: 65px;
        margin: 0 0 0 30px;
      }

      .arrow {
        margin-right: 10px;
        height: 18px;
        color: $main;
        fill: $main;
      }

      .item_description {
        flex-grow: 1;

        .row {
          display: flex;

          .info {
            line-height: 12px;
            font-size: 11px;

            .label {
              margin-bottom: 3px;
              color: $dark_gray;
              text-transform: uppercase;
              white-space: nowrap;
            }

            .value {
              color: $main;

              &.amount {
                font-weight: bold;
              }
              &.state {
                display: inline-block;
                padding: 4px;
                border-radius: 3px;
                color: $white;
              }
              &.success {
                background-color: $green;
              }
              &.warning {
                background-color: $yellow;
              }
              &.pending {
                background-color: $gray3;
              }
              &.error {
                background-color: $red2;
              }
            }

            &:first-child {
              width: 170px;
              margin-right: 25px;
              margin-left: 50px;
            }
          }

          &:not(:last-child) {
            margin-bottom: 10px;
          }
        }
      }
    }
  }

  @media (max-width: $mobile-width) {
    .item .content {
      .image {
        width: 45px;
        margin: 0;
      }

      .item_description {
        .row {
          .info:first-child {
            width: 130px;
            margin: 0 10px;
          }
        }
      }
    }
  }
}

.empty {
  padding: 15px;
  color: $main;
  text-align: center;
}
