@import '../../../styles/colors';
@import '../../../styles/sizes';

#e_invoicing {
  padding: 10px;
  width: 60%;
  margin: 0 auto;

  .card {
    width: 100%;
    box-shadow: 0 0 10px #00000029;
    border-radius: 10px;
    padding: 28px 16px;
  }

  .title {
    color: $dark_red;
    font-size: 14px;
    text-align: center;
    margin-bottom: 30px;
  }

  .img {
    width: 82px;
    height: auto;
    margin-bottom: 20px;
  }

  input,
  input:focus {
    background-color: #f9faff;
    border-color: #e0e7ff;
    height: 40px;
    color: #2e385d;
    line-height: 18px;

    &[type='password'] {
      font-size: 26px;
      padding-left: 10px;
    }
  }

  .password_disabled input {
    color: #cad7e3;
    padding-left: 30px !important;
  }

  .label {
    color: $dark_gray;
    line-height: 14px;
    margin-bottom: 10px;
    display: flex;
    justify-content: space-between;
    font-weight: unset;
    text-transform: uppercase;
    text-align: end;
  }

  .save_button {
    background-color: $main;
  }
  .cancel_button {
    margin-top: 16px;
  }

  .privacy_assurance {
    width: 100%;
    margin-top: 30px;
    padding-top: 15px;

    display: flex;
    align-items: center;
    justify-content: center;
    border-top: 1px solid #d4d4d5;

    .icon {
      margin-right: 10px;
      font-size: 18px;
    }

    .message {
      color: #457e93;
      font-size: 9px;
      line-height: 11px;
    }
  }

  @media (max-width: $mobile-width) {
    width: 90%;
  }
}
