@import '../../styles/colors';
@import '../../styles/reusables';

#blocked_message {
  color: $black;
  line-height: 20px;
}

#hamburger_menu {
  :global {
    .ui.vertical.menu .item:first-child {
      padding: 0;
      margin-bottom: 16px;
    }

    .ui.container {
      margin: 0 !important;
      padding-left: 15px;
      padding-right: 15px;
    }
  }

  .sidebar_menu {
    background-color: $main;
    border: none;

    .profile_item {
      .avatar_image {
        width: 40px;
        height: 40px;
      }

      .profile_item_inner {
        background-color: $dark_red;
        padding: 32px;
        color: $white;
        display: flex;
        cursor: auto;

        .profile_image {
          width: 40px;
          height: 40px;
          float: left;
          margin-right: 14px;
        }

        .profile_content {
          font-size: 11px;
          margin-left: 14px;
          bottom: 7px;
          white-space: nowrap;
          overflow: hidden;
          text-transform: capitalize;
          font-weight: 400;

          p:first-child {
            text-transform: uppercase;
            margin-bottom: 7px;
            overflow: hidden;
            text-overflow: ellipsis;
          }

          .contract_number {
            font-size: 9px;
            white-space: normal;
            text-align: center;
            padding-top: 5px;

            span {
              white-space: nowrap;
            }
          }
        }
      }
    }

    .menu_item {
      padding: 16px 20px 16px 33px;

      span {
        color: $white;
        text-transform: uppercase;
        font-size: 10px;
      }

      .menu_item_icon {
        float: left;
        margin-right: 25px;
        width: 16px;
      }

      &.parent_item {
        padding: 0;
      }
    }

    .menu_item:last-child {
      margin-top: 20px;
    }
  }

  .menu_fluid {
    .menu_header {
      height: $hamburger-menu-height;

      .icon {
        padding: 0;
        color: $dark_gray;

        img {
          width: 16px;
          height: 16px;
        }

        i {
          margin: 0;
        }

        &.hidden {
          visibility: hidden;
        }
      }
    }
  }

  .header_text {
    width: 100%;
    text-align: center;
    margin: auto;
    text-transform: uppercase;
    font-size: 14px;
    color: $dark_gray;
    font-weight: bold;
  }
}
