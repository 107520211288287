@import '../../styles/colors';
@import '../../styles/sizes';

#blocked_message {
  color: $black;
  line-height: 20px;
}
#home_container {
  .apply_to_loan {
    display: flex;
    justify-content: center;
    background-image: url('../../styles/svgs/applyToLoan.png');
    background-position: center center;
    background-repeat: no-repeat;
    background-size: cover;
    position: relative;
    width: auto;
    padding: 25px 40px 30px;
    height: 300px;
    margin-bottom: 50px;

    .get_loan {
      background-color: $main;
      color: $white;
      height: 46px;
      padding: 0 50px;
      border: none;
      border-radius: 6px;
      position: absolute;
      bottom: 0;
      transform: translateY(50%);
      border-radius: 30px;
      cursor: pointer;
    }

    @media (max-width: $mobile-width) {
      background-image: url('../../styles/svgs/applyToLoanMobile.png');
    }
  }
}
