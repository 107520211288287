@import '../../../styles/colors';
@import '../../../styles/sizes';

#change_password {
  padding: 10px;
  width: 60%;
  margin: 0 auto;

  .title {
    color: $dark_gray;
    font-size: 17px;
    text-align: center;
    margin-bottom: 30px;
  }

  .img {
    width: 40px;
    height: auto;
    margin-bottom: 20px;
  }

  .login_input {
    input,
    input:focus {
      background-color: #f9faff;
      border-color: #e0e7ff;
      height: 40px;
      color: #2e385d;
      line-height: 18px;
    }
  }

  .label {
    color: $dark_gray;
    line-height: 14px;
    margin-bottom: 10px;
    display: flex;
    justify-content: space-between;
    font-weight: unset;
    text-transform: uppercase;
    text-align: end;
  }

  @media (max-width: $mobile-width) {
    width: 90%;
  }
}
