@import '../../styles/colors';

$max_width: 360px;

#notification_container {
  background-color: rgba(29, 28, 28, 0.5);

  .notification_block {
    background-color: white;
    border-radius: 3%;
    padding: 20px;
    font-family: 'DejaVu Sans Book', sans-serif;
    max-width: $max_width;
    min-height: 300px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;

    .notification_content {
      font-size: 12px;
      text-align: center;

      .notification_img {
        height: 23px;
        margin-bottom: 15px;
      }

      .notification_header {
        font-size: 20px;
        text-transform: uppercase;
        margin-bottom: 25px;
        color: #4c5a62;
      }

      .notification_message {
        color: #373737b0;
        line-height: 16px;
        font-weight: bolder;
      }

      .notification_date {
        margin-bottom: 10px;
        font-weight: bolder;
        margin-right: 3px;
        color: black;
      }
    }

    .notification_action {
      margin-top: 20px;

      button {
        background-color: $main;
        color: #ffffff;
        width: 150px;
        height: 40px;
        text-transform: capitalize;
      }
    }
  }

  .modal_bottom {
    max-width: $max_width;

    .rectangle {
      height: auto;

      &:first-child {
        padding: 0 10px;
      }

      &:last-child {
        padding: 0 20px;
      }
    }
  }
}
