@import '../../../styles/colors';
@import '../../../styles/sizes';

#business_account {
  .header {
    min-height: 180px;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    background-image: url('../../../styles/svgs/background.svg');
    background-size: cover;
    text-align: center;

    .avatar {
      width: 70px;
      height: 70px;
      background-color: $white;
      border: 2px solid $white;
      border-radius: 50%;
      box-shadow: 0 0 20px #00000029;
      position: relative;
      display: flex;
      align-items: center;
      justify-content: center;

      label {
        width: 100%;
        height: 100%;
        border-radius: 50%;
        display: flex;
        align-items: center;
        justify-content: center;
        overflow: hidden;
        cursor: pointer;
      }
      .image {
        width: 70%;
      }
      .close_icon {
        position: absolute;
        top: -3px;
        right: -3px;
        width: 20px;
        background-color: $white;
        border: 1px solid #cdcdcd;
        border-radius: 50%;
        padding: 2px;
        cursor: pointer;
      }
    }

    .business_account_name {
      margin-top: 30px;
      font-size: 18px;
    }
  }

  .content {
    padding: 0 16px 16px;
    font-size: 14px;
    text-transform: uppercase;

    .content_item {
      border-bottom: 1px solid #e0e8fe;
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding: 20px 0;

      p {
        margin: 0;
        line-height: 16px;

        &:first-child {
          color: #8798ad;
        }
      }
    }
  }
}
