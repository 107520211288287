@import '../../styles/colors';
@import '../../styles/reusables';

.error {
  color: $pink;
  text-align: left;
  display: block;
  width: 100%;
  height: 30px;
  font-size: 11px;
  line-height: 13px;
  margin-top: 2px;
}

.server_error {
  text-align: center;
  color: $white;
  padding: 15px 20px;
  font-size: 14px;
}

.invalid {
  input,
  textarea {
    border: 1px solid $pink !important;
    background-color: $darker-skin !important;
  }
}

:global(.ui.form .field.field) {
  margin-bottom: 0.2em;
  .invalid {
    input:-webkit-autofill,
    input:-webkit-autofill:hover,
    input:-webkit-autofill:focus {
      border-color: $pink !important;
      box-shadow: 0 0 0px 1000px $darker-skin inset !important;
      -webkit-box-shadow: 0 0 0px 1000px $darker-skin inset !important;
    }
  }
}
