@import '../../styles/colors';
@import '../../styles/reusables';
@import '../../styles/sizes';

#suppliers {
  .search,
  .search > div {
    width: 100%;
  }

  .search input {
    border-radius: 0;
  }

  .search i {
    color: $dark_gray;
  }

  padding: 10px;

  :global(.ui.list .item:first-child) {
    padding-top: 10px;
  }

  :global(.ui.list .item:last-child) {
    padding-bottom: 10px;
  }

  :global(.ui.list .item) {
    display: flex;
    align-items: center;
    width: 100%;
    margin-bottom: 10px;
    box-shadow: $box-shadow;
    padding: 10px;

    img.avatar_image {
      height: 35px;
      width: 55px;
    }

    .content {
      flex-grow: 1;
      padding-left: 3px;

      .header {
        color: $main;
        font-size: 14px;
        line-height: 16px;
        text-transform: uppercase;
        font-weight: bold;
        margin-bottom: -2px;
      }

      .title {
        font-weight: bold;
        font-size: 10px;
        color: black;
      }

      .description {
        margin-top: 3px;
        color: #707070;
        font-size: 10px;
        line-height: 11px;

        span {
          white-space: nowrap;
        }
      }
    }

    @media (max-width: $mobile-width) {
      .header {
        font-size: 11px;
        line-height: 11px;
        font-weight: bold;
      }
    }
  }
}
