@import './styles/fonts';
@import './styles/colors';

$font-family: 'DejaVu Sans Book', 'Arial', sans-serif;

body {
  overflow-y: auto;
  overflow-x: hidden;
  height: 100%;
  width: 100%;
  margin: 0;
  font-family: $font-family;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

:global {
  .ui.list .list > .item .header,
  .ui.list > .item .header,
  .ui.form .ui.input > input,
  .ui.form .ui.input > input:focus,
  .ui.form textarea,
  .ui.button,
  .ui.menu {
    font-family: $font-family;
  }

  .ui.form .ui.input > input,
  .ui.form .ui.input > input:focus,
  .ui.form textarea {
    font-size: 16px;
  }

  form .ui.label {
    text-transform: uppercase;
    color: $mojo_red;
    font-weight: inherit;
    font-size: 14px;
    padding: 0;
    background-color: $white;
  }

  .Toastify > div {
    top: 58px;
    left: 0;
    width: 100%;
    margin-left: 0;

    [class*='body'] {
      font-family: $font-family;
    }

    [class*='success'] {
      background-color: #7ef4a1;
    }

    [class*='error'] {
      background-color: #ff6878;
    }
  }

  // IMPORTANT: A hack for ios, making the background not scroll, when modal is opened
  body.dimmed {
    overflow: hidden;
    position: fixed;
  }

  .update-modal {
    .content p {
      color: $black;
      line-height: 20px;
      padding-right: 10px;
    }

    .actions {
      display: flex;
      flex-direction: column;
      align-items: center;

      .ui.button.gc_default {
        height: 45px;
        width: 140px;
        margin: 0 0 1rem;
      }
    }
  }
  .ui.dimmer {
    background-color: rgba(0, 0, 0, 0.5);
    position: fixed;
  }
}
