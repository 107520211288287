@import '../../styles/colors';

$red: #f94b4d;

.speedometer {
  position: relative;
  width: auto;
  padding: 25px 40px 30px;
  background-color: $main;
  color: $white;
  height: 215px;

  .speedometer_container {
    max-height: 100%;
    height: 100%;
    position: relative;

    .balance {
      position: absolute;
      left: 0;
      right: 0;
      bottom: -25px;

      .balance_container {
        display: flex;
        justify-content: center;
        font-size: 22px;
        line-height: 30px;
        margin-bottom: 7px;

        svg {
          height: 17px;
        }
      }
    }

    .title {
      color: $white;
      opacity: 0.6;
      font-weight: bold;
      font-size: 13px;
      line-height: 15px;
      text-align: center;
      text-transform: uppercase;
    }

    .info_container {
      display: flex;
      justify-content: center;
      align-items: center;
      height: 20px;

      .label {
        background-color: $red;
        border-color: $red;
        margin-right: 4px;
        font-size: 5px;
      }

      .info {
        font-size: 8px;
        display: inline;
        color: $white;
        opacity: 0.6;
      }
    }
  }
}
